import React from 'react';
import { Link } from "gatsby";
import Layout from '../components/layout';
import Box, { BoxParagraph, BoxTitle } from '../elements/box';
import Diapo from '../elements/diapo';
import Card, { CardContent, CardImage, CardTitle} from '../elements/card';

const Groupes_residentiels = () => (
  <Layout>
    <Box background="primary-light">
      <BoxTitle title="Groupe de thérapie en résidentiel sur l’Ile de Lanzarote" />
    </Box>
    <div className="columns">
      <div className="column">
        <Card>
          <CardContent>
            <CardTitle title="Pour qui ?" />
            <br />
            <div className="content centered-content">
              <p><em>Groupe de 8 personnes.</em></p>
              <p>Les personnes ayant déjà un parcours en thérapie individuelle et désireuses de se mettre au travail dans un 
                environnement contenant et favorable au déploiement de soi.</p>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="column is-3">
        <Card height="">
          <CardImage src="gestalt2" alt="lanzarote" />
        </Card>
      </div>
      <div className="column">
        <Card>
          <CardContent>
            <CardTitle title="Pourquoi ?" />
            <br />
            <div className="content centered-content">
              <p>Découvrir ses ressources, internes et dans la relation à l’autre.</p>
              <p>Travailler sur ses zones aveugles.</p>
              <p>S’appuyer sur les éléments : mer, air, volcans que nous offre l’île pour explorer ses contraintes existentielles.</p>
              <p>Développer le registre des sensations et des éprouvés pour potentialiser son être au monde.</p>
              
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
    <Box background="primary-light">
      <BoxTitle title="Avec qui ?" />
      <div className="columns">
        <div className="column is-2">
          <Card height="">
            <CardImage src="nportrait5" alt="portrait" />
          </Card>
        </div>
        <div className="column">
          <div className="columns">
            <div className="column">
              <BoxParagraph>
                <p><em>Anne DINDAR VALIENTE</em></p>
                <p>Je suis tombée en amour de cette île depuis maintenant 10 ans. Je l’ai parcouru en long et en large et je souhaite 
                  partager avec d’autres sa douceur, son énergie vitale et sa beauté.</p>
              </BoxParagraph>
            </div>
            <div className="column">
              <BoxParagraph>
                <p><em>Béatrice WUEST</em></p>
                <p>Gestalt praticienne, ma participation se fera à travers la danse des 4 éléments (terre, air, feu, eau) et le chant.</p>
              </BoxParagraph>
            </div>
          </div>
          <BoxParagraph>
            <p><em>"Riches de nos compétences complémentaires et de nos personnalités d’exploratrice des âmes, nous avons co construit un 
              séjour dans cette ile volcanique qui réunit tous les éléments propices à un retour vers soi. Lanzarote est un terrain de 
              jeu extraordinaire avec une énergie vivifiante et apaisante. Notre souhait, faciliter le processus du groupe et soutenir 
              la singularité de chaque personne. Les ateliers que nous proposons seront un chemin pour mettre son corps en mouvement et 
              ETRE EN CORPS."</em></p>
          </BoxParagraph>
        </div>
        <div className="column is-2">
          <Card height="">
            <CardImage src="collegue" alt="portrait_collegue" />
          </Card>
        </div>
      </div>
    </Box>
    <div className="columns">
      <div className="column is-half">
        <Diapo
          backgroundImages={[ 
            "lanzarote1",
            "contes",
            "lanzarote3",
          ]}
          alt="lanzarote"
          size="is-4by3"
        />
      </div>
      <div className="column">
        <Card>
          <CardContent>
            <CardTitle title="Quand ?" />
            <br />
            <div className="content centered-content">
              <p>Prochains séjours : du 17 au 24 septembre 2022 (3 places restantes), du 29 avril au 6 mai 2023 (COMPLET), du 3 au 10 juin 2023 et du 9 au 16 septembre 2023.</p>
            </div>
            <CardTitle title="Où ?" />
            <br/>
            <div className="content centered-content">
              <p>Lanzarote, île de l'archipel des Canaries située au large de la côte africaine et administrée par l'Espagne, 
                est réputée pour son temps chaud toute l'année, ses plages et ses volcans. Le paysage rocheux du parc national de 
                Timanfaya a été créé par des éruptions volcaniques dans les années 1730. La Cueva de los Verdes comprend des 
                cavernes formées par une rivière de lave souterraine. Sur la côte est, la station balnéaire de Puerto del Carmen 
                abrite des villas blanches à la chaux, des plages et des centres de plongée.</p>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
    <div className="columns">
      <div className="column is-one-quarter">
        <Card>
          <CardImage src="yoga" alt="yoga" size="is-9by16" />
        </Card>
      </div>
      <div className="column is-one-quarter">
        <Card>
          <CardContent>
            <CardTitle title="Quel programme ?" />
            <br />
            <div className="content centered-content">
              <p>Marches méditatives</p>
              <p>Yoga sur la plage</p>
              <p>Expérimentations corporelles à découvrir et temps de paroles</p>
              <p>Dégustations sensorielles</p>
              <p>Baignades</p>
              <p>Rituels dans les volcans</p>
              <p>Balade en bateau</p>
              <p>Possibilité de recevoir un massage</p>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="column is-half">
        <Diapo 
          backgroundImages={[ 
            "contes2",
            "lanzarote7",
            "lanzarote6",
          ]}
          alt="lanzarote"
          size="is-4by3"
        />
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <Card height="">
          <CardImage src="degustation2" alt="degustation" size="is-4by3"/>
        </Card>
      </div>
      <div className="column">
        <Card height="">
          <CardImage src="degustation1" alt="degustation" size="is-4by3"/>
        </Card>
      </div>
      <div className="column">
        <Card height="">
          <CardImage src="degustation3" alt="degustation" size="is-4by3"/>
        </Card>
      </div>
      <div className="column">
        <Card height="">
          <CardImage src="degustation4" alt="degustation" size="is-4by3"/>
        </Card>
      </div>
    </div>
    <Box background="primary-light">
      <div className="columns">
      <div className="column is-3">
          <Card>
            <CardContent>
              <CardTitle title="Combien?" />
              <br />
              <div className="content centered-content">
                <p>Prix de journée pour le travail thérapeutique : 150 X 6 jours soit 900 euros.</p>
                <p><em>Un acompte de 30% sera demandé au moment de l’inscription pendant la réunion 
                  d’information.</em></p>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="column is-3">
          <Diapo 
            backgroundImages={[ 
              "lanzarote5",
              "gestalt2"
            ]}
            alt="lanzarote"
            size="is-9by16"
          />
        </div>
        <div className="column">
          <Card>
            <CardContent>
              <CardTitle title="Que prévoir ?" />
              <br />
              <div className="content centered-content">
                <p>Vous pouvez réserver un vol direct avec la compagnie Vueling : 3h50 de vol avec départ d'Orly à 7h30 le samedi 
                  matin et retour le samedi suivant vers 16h <em>(environ 200 euros)</em>.</p>
                <p>Location de voiture dès l’arrivée à l’aéroport <em>(environ 50 euros par participant, essence comprise)</em>.</p>
                <p>Logement en AirBnb à la Caleta de Famara <em>(environ 200 euros la semaine)</em>.</p>
                <p>Les frais de petit-déjeuner, déjeuner et dîner sont à la charge de chaque participant <em>(environ 60 euros 
                  par jour)</em>.</p>
                <p>Sortie en bateau sur l’île de Graciosa <em>(50 euros aller-retour)</em>.</p>
                <em>(Les prix sont donnés à titre indicatif.)</em>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </Box>
    <Link to='/temoignages'>
      <Box background="primary-light">
        <BoxTitle title="Témoignages" />
        <div className="columns">
          <div className="column">
            <Card height="">
              <CardImage src="lanzarote4" alt="lanzarote" size="is-4by3"/>
            </Card>
          </div>
          <div className="column">
            <Card height="">
              <CardImage src="reflexions" alt="lanzarote" size="is-4by3"/>
            </Card>
          </div>
          <div className="column">
            <Card height="">
              <CardImage src="lanzarote2" alt="lanzarote" size="is-4by3"/>
            </Card>
          </div>
          <div className="column">
            <Card height="">
              <CardImage src="gestalt" alt="lanzarote" size="is-4by3"/>
            </Card>
          </div>
        </div>
      </Box>
    </Link>
  </Layout>
)

export default Groupes_residentiels;